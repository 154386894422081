<template>
    <div class="app-vue">
        <header id="header">
            <div class="fondo-abs">
                    <div class="container">
                        <div class="row first-header">
                            <div class="col-12 col-md-4">
                                <img src="../assets/image/LOGOB.png" alt="BextSocial" />
                            </div>
                            <div class="col-12 col-md-8">
                                <nav>
                                    <button class="navbar-toggler d-none" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span class="navbar-toggler-icon"></span>
                                    </button>
                                    <div id="menu" class="collapse show">
                                        <ul class="no-padding content-menu">
                                            <li class="padre">
                                                <!--<router-link to="#contact" class="nav-link">RESERVA DEMO</router-link>-->
                                                <a href="#" @click="scrollTop()" title="Dirigite a reserva una demo" class="nav-link"> {{$t('menu.item1')}} </a>
                                            </li>
                                            <!-- <li class="padre">
                                                <router-link :to="$i18nRoute({name: 'Precios'})" title="Dirigite a Precios" class="nav-link">{{$t('menu.item2')}}</router-link>
                                            </li> -->
                                            <li class="padre">
                                                <a href="#" @click="scrollTop()" title="Dirigite a Contáctanos" class="nav-link">{{$t('menu.item3')}}</a>
                                            </li>
                                            <li class="padre">
                                                <button type="button" class="nav-link excep-link" v-if="!this.isLogeedIn" @click="$auth.login()">{{$t('menu.item4')}}</button>
                                                <router-link :to="$i18nRoute({name: 'Home'})" v-else title="Dirigite al tablero de gestión" class="nav-link excep-link">{{$t('menu.item4')}}</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                        <div class="row sencond-header">
                            <div class="col-12 col-md-5">
                                <h1> {{ $t('landing.title') }}</h1>
                                <p> {{$tc('landing.subtitle')}} </p>
                                <button type="button" class="btn btn-bextsocial" @click="scrollTop()">{{$tc('landing.button')}} </button>
                                <!-- <router-link :to="$i18nRoute({name: 'Home'})" title="Inicia" v-else class="btn btn-bextsocial">{{$tc('landing.button')}} </router-link> -->
                            </div>
                            <div class="col-12 col-md-7">
                                <span class="d-none">.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="container">
                    <div class="row three-header">
                        <div class="col-12 padd-left-110 padd-right-110">
                            <div class="logos-redes">
                                <font-awesome-icon :icon="['fab', 'facebook-f']" size="3x"/>
                                <font-awesome-icon :icon="['fab', 'instagram']" size="3x"/>
                                <font-awesome-icon :icon="['fab', 'twitter']" size="3x"/>
                                <font-awesome-icon icon="globe-americas" size="3x"/>
                            </div>
                            <p>{{$tc('landing.mencion')}} </p>
                        </div>
                    </div>
                </div> -->
        </header>
        <router-view></router-view>
        
        <footer id="footer">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-3 descrip-social">
                        <h6>BEXTSOCIAL</h6>
                        <p>Supervise las menciones de su marca o producto en línea, interactúe con su audiencia y personas influyentes, aumente el conocimiento de la marca.</p>
                        <div class="redes-bext">
                            <a href="https://www.facebook.com/BEXTechnology" title="Dirigite a Facebook" acceskey="U">
                                <font-awesome-icon :icon="['fab', 'facebook-f']" size="3x"/>
                            </a>
                            <a href="https://www.linkedin.com/company/bextechnology/" title="Dirigite a Linkedin" acceskey="U">
                                <font-awesome-icon :icon="['fab', 'linkedin-in']" size="3x"/>
                            </a>
                            <a href="https://twitter.com/Bextechnology" title="Dirigite a Twitter" acceskey="U">
                                <font-awesome-icon :icon="['fab', 'twitter']" size="3x"/>
                            </a>
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        <h6>Links</h6>
                        <ul class="no-padding">
                            <li>
                                <a href="#" title="Dirigite al login" v-if="!this.isLogeedIn" @click="$auth.login()" acceskey="U">Inicio</a>
                                <router-link to="/home" v-else title="Dirigite al login" acceskey="U">Inicio</router-link>
                            </li>
                            <li>
                                <a href="#" title="Dirigite a Soluciones" acceskey="U">Soluciones</a>
                            </li>
                            <li>
                                <a href="#" title="Dirigite a Características" acceskey="U">Características</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-3">
                        <h6>Soporte</h6>
                        <ul class="no-padding">
                            <li>
                                <a href="#" title="Dirigite a Login" acceskey="U" v-if="!this.isLogeedIn" @click="$auth.login()">Iniciar sesión</a>
                            </li>
                            <li>
                                <a href="#" title="Dirigite a Contáctanos" @click="scrollTop()" acceskey="U">Contacto</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-3">
                        <h6>Contácto</h6>
                        <ul class="no-padding">
                            <li>+57 xxxxxxxxxxx</li>
                            <li>mercadeo@bextsocial.com</li>
                            <li>Bogotá, Colombia</li>
                        </ul>
                        <div class="politica">
                            <span>Política de Privacidad</span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
    
</template>

<script>
import jQuery from "jquery";
import { locales } from 'moment';
window.$ = window.jQuery = jQuery;
export default {
  name: "Landing",
  data() {
      return {
          isLogeedIn: this.$auth.isAuthenticated(),
      }
  },
  methods: {
      scrollTop(){
        // this.$gtag.event('Solicitar-Demo', {
        //     'event_category': 'Request',
        //     'event_label': 'Solicitar demo',
        //     'value': 1
        // })
        $('html, body').animate({
            scrollTop: $("#contact").offset().top
        }, 2000);
      },
  },
};
</script>
<style lang="sass" scoped>

.menu-idiomas 
    padding: 0;
    list-style: none;
    li
        cursor: pointer;
        color: #fff;
    
</style>