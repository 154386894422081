<template>
  <div id="app">
    <sidebar :data-color="computedColors"></sidebar>
    <div class="main-panel" :class="{'main-close': !$store.state.close}">
      <navbar :data-color="computedColors"></navbar>
      <router-view />
    </div>
    <!--<div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/secret">Secret</router-link>
    </div>
    <router-view />-->
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  .main-panel{
    margin-left: 260px;
    background: #F0F0F7;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding-bottom: 10px;
    &.main-close{
      margin-left: 66px;
    }
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script>
import {mapState} from 'vuex';
export default {
  name: "Aplication",
  data() {
    return {
      backgroundColor: "primary",
      backgroundNavbar: "primary"
    };
  },
  computed: {
    ...mapState("colors", ["sidebarColors"]),
    computedColors(){
      const prueba = this.sidebarColors.filter(item => item.active == true);
      return prueba[0].color
    }
  },
};
</script>